import React, { useEffect, useState } from "react";
import { Seo } from "../../../components/seo";
import Layout from "../../../hoc/Layout/Layout";
import Booking from "../../../components/Booking/Booking";
import Instructions from "../../../components/Instructions/Instructions";
import GradeSection from "../../../components/GradeSection/GradeSection";
import Faq from "../../../components/FAQ/FAQFlyttstadning";
import FlyttstadningQ from "../../../components/FAQ/FlyttstadningQ";
import AdvantagesSection from "../../../components/AdvantagesSection/AdvantagesSection";
import { SERVICE_TYPES } from "../../../enums/serviceTypes";
import { navigate } from "gatsby";

// markup
const IndexPage = () => {
	const [bookingActive, setBookingActive] = useState(false);
	const [serviceChosen, setServiceChosen] = useState(false);
	const [ableToGoBack, setAbleToGoBack] = useState(false);
	const [finishBookingStep, setFinishBookingStep] = useState();

	useEffect(() => {
		const city = localStorage.getItem('city');
		if (city !== 'linkoping') {
			navigate('/tjanster/flyttstadning')
		}
	}, []);

	return (
		<Layout
			bookingActive={bookingActive}
			serviceChosen={serviceChosen}
			ableToGoBack={ableToGoBack}
			finishBookingStep={finishBookingStep}
			setFinishBookingStep={setFinishBookingStep}
		>
			<Seo title="Flyttstädning i Linköping | DreamClean" />
			<Booking
				setBookingActive={setBookingActive}
				choosenService={SERVICE_TYPES.Flyttstädning}
				bgType2
				firstStep={2}
				setServiceChosen={setServiceChosen}
				setAbleToGoBack={setAbleToGoBack}
				finishBookingStep={finishBookingStep}
				setFinishBookingStep={setFinishBookingStep}
			/>
			{!bookingActive && (
				<>
					<AdvantagesSection
						type={SERVICE_TYPES.Flyttstädning}
						text1={{
							heading: "Socialt ansvarstagande hemstädningsföretag",
							description:
								"Vi har kollektivavtal med Almega och våra anställda får självklart betald semester, pension, friskvårdsbidrag och andra förmåner.",
						}}
						text2={{
							heading: "Kvalitetsgaranti",
							description:
								"Med 5 dagars kvalitetsgaranti kan vi garantera en enklare vardag, där vi snabbt kan ställa allt till rätta om det skulle vara något du är mindre nöjd med.",
						}}
						text3={{
							heading: "Ansvarsförsäkring",
							description:
								"Du kan känna dig trygg med att vi har en försäkring som täcker eventuella skador ifall en olycka skulle framkomma. Såklart är alla våra anställda också fullt försäkrade.",
						}}
						text4={{
							heading: "Miljömärkta medel ingår",
							description:
								"Vi värdesätter miljön och agerar så ansvarsfullt som möjligt när det kommer till alla steg i vår städtjänst. Därför tar vi med oss miljövänliga medel när vi flyttstädar - utan att kompromissa med kvaliteten.",
						}}
					/>
					<FlyttstadningQ />
					<Instructions
						// bg
						text1="Ange ditt postnummer och hur stor din bostad är."
						text2="Se pris och välj det datum du önskar få flyttstädningen utförd. Vi återkopplar om datumet finns ledigt."
						text3="Vi utför flyttstädningen så att du kan lägga all energi i ditt nya hem. Vårt professionella städteam gör ordentligt rent in i minsta detalj."
						heading1="Beräkna ditt pris"
						heading2="Få städning utförd"
						heading3="Njut av ditt nya hem"
					/>
					<Faq />
					<GradeSection />
				</>
			)}
		</Layout>
	);
};

export default IndexPage;
